import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
// import ReplyAllOutlinedIcon from '@material-ui/icons/ReplyAllOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import DvrIcon from '@material-ui/icons/Dvr';

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import {makeStyles} from "@material-ui/core/styles";
import api from "../services/api";
import toastError from "../errors/toastError";
import openSocket from "socket.io-client";

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  const classes = useClasses();

  return (
      <li className={classes.icon}>
          <ListItem button component={renderLink} className={className}>
              {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
              <ListItemText className={classes.icon} primary={primary} />
          </ListItem>
      </li>
  );
}

function ListItemNoLink(props){
    const { icon, primary, className } = props;

    const classes = useClasses();

    return (
        <li className={classes.icon}>
            <ListItem button className={className} disabled={true}>
                {icon ? <ListItemIcon disabled={true} className={classes.icon}>{icon}</ListItemIcon> : null}
                <ListItemText className={classes.icon} primary={primary} />
            </ListItem>
        </li>
    );
}

const useClasses = makeStyles(theme => ({
    icon: {
        color: '#f3f3f3',
        "&:hover": {
            color: "#b0b0b0",
            "& .MuiListItemIcon-root": {
                color: "#b0b0b0"
            }
        }
    },
}));

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [botWarning, setBotWarning] = useState(false);
  const [allowAgendador, setAllowAgendador] = useState("disabled");
  // const [allowEnvioMassa, setAllowEnvioMassa] = useState("disabled");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const { data } = await api.get("/settings");

                const ag = data.find(s => s.key === 'allowAgendador')
                if (ag?.value)
                    setAllowAgendador(ag.value)

                // const em = data.find(s => s.key === 'allowEnvioMassa')
                // if (em?.value)
                //     setAllowEnvioMassa(em.value)
            } catch (err) {
                toastError(err);
            }
        };
        fetchSettings();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/ticket2");

                setBotWarning(data?.length);

            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    useEffect(() => {
        const socket = openSocket(window.env?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL);

        socket.on("ticketBot", async (data) => {
            if (data.action === "update" || data.action === "create" || data.action === "reload") {
                try {
                    const { data } = await api.get("/ticket2");

                    setBotWarning(data?.length);

                } catch (err) {
                    toastError(err);
                }
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

  return (
    <div onClick={drawerClose}>
        <Divider style={{ backgroundColor: "#fcfcfc" }} />

        <ListSubheader inset style={{ color: "#e0e0e0"}}>
            <label>Tickets</label>
        </ListSubheader>

        <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon />}
        />

        <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon />}
        />
        <ListItemLink
        to="/quickAnswers"
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<ChatOutlinedIcon />}
        />
        <ListItemLink
        to="/protocolos"
        primary={"Protocolos"}
        icon={<DvrIcon />}
        />

        <Divider style={{ backgroundColor: "#fcfcfc" }} />

        <ListSubheader inset style={{ color: "#e0e0e0"}}>
            <label>ChatBot</label>
        </ListSubheader>

        <ListItemLink
            to="/ticketsBot"
            primary="BOT Atendendo"
            // icon={<PersonOutlineOutlinedIcon />}
            icon={
                <Badge overlap="rectangular" badgeContent={botWarning ?? 0} color="error">
                    <PersonOutlineOutlinedIcon/>
                </Badge>
            }
        />

        <ListItemLink
            to="/perguntas"
            primary={i18n.t("mainDrawer.listItems.chatbots")}
            icon={<QuestionAnswerOutlinedIcon />}
        />

        <ListItemLink
            to="/tags"
            primary="Tags"
            icon={<LocalOfferOutlinedIcon />}
        />

        {/*{ allowEnvioMassa === "enabled" ? (
            <ListItemLink
                to="/envios"
                primary="Envios em Massa"
                icon={<ReplyAllOutlinedIcon />}
            />
        ) : (
            <ListItemNoLink
                primary="Envios em Massa"
                icon={<ReplyAllOutlinedIcon />}
            />
        ) }*/}

        { allowAgendador === "enabled" ? (
            <ListItemLink
                to="/agendamentos"
                primary="Agendamentos"
                icon={<CalendarTodayIcon />}
            />
        ) : (
            <ListItemNoLink
                primary="Agendamentos"
                icon={<CalendarTodayIcon />}
            />
        ) }

        <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
                <>

                    {/*
            <ListItemLink
              to="/chatbots"
              primary={i18n.t("mainDrawer.listItems.contatoschatbots")}
              icon={<QuestionAnswerOutlinedIcon />}
            />

            <ListItemLink
              to="/tags"
              primary={i18n.t("mainDrawer.listItems.tags")}
              icon={<QuestionAnswerOutlinedIcon />}
            />

            <ListItemLink
              to="/tagsCliente"
              primary={i18n.t("mainDrawer.listItems.tagsCliente")}
              icon={<QuestionAnswerOutlinedIcon />}
            />
        */}

                </>
            )}
        />

        <Can
            role={user.profile}
            perform="drawer-admin-items:view"
            yes={() => (
                <>
                    <Divider style={{backgroundColor: "#fcfcfc"}}/>
                    <ListSubheader inset style={{color: "#e0e0e0"}}>
                        {i18n.t("mainDrawer.listItems.administration")}
                    </ListSubheader>
                    <ListItemLink
                        to="/"
                        primary="Dashboard"
                        icon={<DashboardOutlinedIcon/>}
                    />
                    <ListItemLink
                        to="/connections"
                        primary={i18n.t("mainDrawer.listItems.connections")}
                        icon={
                            <Badge overlap="rectangular" badgeContent={connectionWarning ? "!" : 0} color="error">
                                <SyncAltIcon/>
                            </Badge>
                        }
                    />
                    <ListItemLink
                        to="/users"
                        primary={i18n.t("mainDrawer.listItems.users")}
                        icon={<PeopleAltOutlinedIcon/>}
                    />
                    <ListItemLink
                        to="/queues"
                        primary={i18n.t("mainDrawer.listItems.queues")}
                        icon={<AccountTreeOutlinedIcon/>}
                    />
                    <ListItemLink
                        to="/settings"
                        primary={i18n.t("mainDrawer.listItems.settings")}
                        icon={<SettingsOutlinedIcon/>}
                    />
                </>
            )}
        />
    </div>
  );
};

export default MainListItems;
